import React, { Fragment, useContext, useMemo } from "react";

import LogoFZSD from "../assets/fsdigital-logo.svg"

import { StoreContext } from "../hooks/store";

export const KuesPage: React.FunctionComponent<{ children?: React.ReactNode }> = (props) => {
    const { auth } = useContext(StoreContext)

    const rootUrl = useMemo(() => {
        return window.location.origin + window.location.pathname; // anpassen, sobald ein Router verwendet wird!
    }, [])

    const logoutBtn = auth.user
        ? <button style={{ fontFamily: "Roboto" }} className="btn btn-link float-right" onClick={auth.logout}>
            Logout
        </button>
        : undefined

    const logoHeight = "70px"
    const margin = "0 20px 20px 0px"

    const header = <div className="container" style={{ padding: "1em" }}>
        {logoutBtn}
        <a href={rootUrl}>
            <img src={LogoFZSD} alt="Logo" style={{ margin: margin, height: logoHeight }} />
        </a>
    </div>

    return <Fragment>
        {header}
        <div className="container" style={{ padding: "1em" }}>
            <div className="content">
                {props.children}
            </div>
        </div>
    </Fragment>
}