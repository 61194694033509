import { API } from "../api/api";

import { createContext } from "react"
import { useAuth } from "./auth";

export type Store = ReturnType<typeof useStore>

export const StoreContext = createContext<Store>(undefined as any)

export function useStore(api: API) {
    const auth = useAuth(api)
    return {
        api: api,
        auth: auth,
    }
}


