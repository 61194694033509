import React, { useState, useCallback, Fragment, useRef, useEffect } from "react"

import { API } from "../../api/api"
import { toBase64 } from "../../utils"

import "image-capture" // Polyfill for Firefox


export function ZB1EingabeVonKamera(props: {
    setScan: (scan: API.AuswertungParamsInternal) => void
}) {
    const { setScan } = props;
    const videoRef = useRef<HTMLVideoElement | null>(null)
    const [supported, setSupported] = useState(false)
    const [stream, setStream] = useState<MediaStream | undefined>(undefined)
    const [videoDevices, setVideoDevices] = useState<MediaDeviceInfo[]>([])
    const [selectedDeviceId, setSelectedDeviceId] = useState<string | undefined>(undefined)

    // Liste alle Kameras auf
    useEffect(() => {
        let canceled = false;
        (async function () {
            try {
                // Berechtigung holen. Stream wieder schließen...
                const tmpStream = await navigator.mediaDevices.getUserMedia({ video: true });
                if (canceled) return;
                tmpStream.getTracks().forEach(t => t.stop())

                // "etwas" warten, sodass ein neuer Stream geöffnet werden darf. Gibt es eine bessere Möglichkeit???
                await new Promise(res => setTimeout(res, 200));
                if (canceled) return;

                // Geräte auflisten
                const allDevices = await navigator.mediaDevices.enumerateDevices();
                if (canceled) return;
                const allVideoDevices = allDevices
                    .filter(d => d.kind === "videoinput");

                setVideoDevices(allVideoDevices)

                if (allVideoDevices.length > 0) {
                    setSelectedDeviceId(allVideoDevices[0].deviceId)
                }
                setSupported(true)
            } catch (error) {
                setSupported(false)
            }
        }())
        return () => { canceled = true }
    }, [])

    // Wenn eine Kamera ausgewählt wird, dann öffne einen Stream
    useEffect(() => {
        let canceled = false;
        (async function () {
            try {
                // Bestehenden Stream schließen
                setStream(s => {
                    s?.getTracks().forEach(t => t.stop())
                    return undefined;
                })
                // "etwas" warten, sodass ein neuer Stream geöffnet werden darf. Gibt es eine bessere Möglichkeit???
                await new Promise(res => setTimeout(res, 200));
                if (canceled) return;
                // Stream öffnen
                if (selectedDeviceId) {
                    const selectedStream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: selectedDeviceId } });
                    if (canceled) return;
                    setStream(selectedStream);
                }
            } catch (error) {
                alert("Fehler: Kamera kann nicht verwendet werden: " + error) // sollte nicht vorkommen
            }
        }())
        return () => { canceled = true }
    }, [selectedDeviceId, setStream])

    // Wenn ein Stream verfügbar ist, dann verbinde ihn mit dem Video-Element
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.srcObject = stream ?? null;
        }
    }, [stream, videoRef])

    // Stream "schließen" wenn die Komponente entfernt wird
    useEffect(() => {
        return () => {
            if (stream) {
                stream.getTracks().forEach(t => t.stop())
            }
        }
    }, [stream])

    // Benutzeraktion: Snapshot machen, Auswertung anstoßen
    const onSnapshot = useCallback(async () => {
        if (stream) {
            const track = stream.getVideoTracks()[0];
            const imageCapture = new ImageCapture(track)
            const photo = await imageCapture.takePhoto()
            const photoBase64 = await toBase64(photo)
            setScan({ type: "image", base64Data: photoBase64 })
        }
    }, [stream, setScan])

    // Benutzeraktion: Kamera gewählt
    const onDeviceSelected = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.currentTarget.value;
        setSelectedDeviceId(value === "" ? undefined : value)
    }, [setSelectedDeviceId])

    if (supported) {
        return <Fragment>
            <div className="form-group">
                <select
                    className="form-control"
                    value={selectedDeviceId}
                    onChange={onDeviceSelected}>

                    <option value={""}>Keine Kamera gewählt</option>
                    {
                        videoDevices
                            .map(device => (
                                <option key={device.deviceId} value={device.deviceId}>
                                    {device.label === "" ? "Unbekanntes Gerät" : device.label}
                                </option>
                            ))
                    }
                </select>
            </div>
            <video
                onClick={onSnapshot}
                style={{ marginBottom: "1em", width: "100%" }}
                autoPlay={true}
                ref={videoRef}
            />
            <button
                className="btn btn-primary"
                disabled={stream === undefined}
                onClick={onSnapshot}>
                Auswertung starten
            </button>
        </Fragment>
    } else {
        return <Fragment>
            Keine Kamera verfügbar
        </Fragment>
    }

}