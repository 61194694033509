import { useState, useCallback, useEffect } from "react"
import { API } from "../api/api"

export function useAuth(api: API) {
    const [user, setUser] = useState<API.UserResult | undefined>(undefined)

    // Prüfen, ob Logindaten vorhanden
    useEffect(() => {
        let canceled = false;
        setUser(undefined)
        const promise = api.user({})
        promise.then((u) => {
            if (canceled) return;
            setUser(u)
        })
        return () => { canceled = true }
    }, [setUser, api])

    const logout = useCallback(async () => {
        await api.logout()
        setUser(undefined)
    }, [api, setUser]);

    const login = useCallback(async (loginname: string, passwort: string) => {
        setUser(undefined)
        try {
            await api.login({ loginname, passwort })
            const userResult = await api.user({})
            setUser(userResult)
        } catch (error) {
            throw error
        }
    }, [api, setUser]);

    return {
        user, logout, login
    }
}

