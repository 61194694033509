import React, { useMemo } from "react"


import Prism from "prismjs"
import "prismjs/themes/prism-coy.css"

import "prismjs/components/prism-json"

type LanguageCode = "json"; // | "js" | "ts" | ...

export function CodeBox(props: {
    code: string,
    language: LanguageCode
}) {
    if (props.language !== "json") {
        console.error("Aktuell nur JSON unterstützt! TODO: Syntax-Highlighting für andere Sprachen einbauen.")
    }
    const html = useMemo(() => Prism.highlight(props.code, Prism.languages.json, 'json'), [props.code]);
    return <pre>
        <code dangerouslySetInnerHTML={{ __html: html }} />
    </pre>
}
