import { useContext, useState, Fragment } from "react"

import { StoreContext } from "../hooks/store"
import { KuesPage } from "../components/kues-page"

export function PageLogin() {
    const { auth } = useContext(StoreContext)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const [pending, setPending] = useState(false);
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")

    const onLogin = async () => {
        setPending(true)
        setErrorMessage(undefined)
        try {
            await auth.login(userName, password)
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'Unbekannte Ursache';
            setPending(false)
            console.error("Fehler beim Einloggen", error)
            setErrorMessage(`Fehler beim Einloggen. Versuche es erneut. (${errorMessage})`); // TODO: Nachricht vom Backend! 
        } finally {
            // setPending(false) 
        }
    }

    const errorMessageJSX = (errorMessage)
        ? <div className="alert alert-danger">{errorMessage}</div>
        : <Fragment />

    return <KuesPage>

        <div className="card">
            <div className="card-header">Login</div>
            <div className="card-body">
                {errorMessageJSX}
                <form onSubmit={e => { e.preventDefault(); onLogin(); return false; }} >

                    <div className="form-group">
                        <label className="">Name</label>
                        <input
                            autoComplete="username"
                            className="form-control"
                            type="text"
                            id="username"
                            required
                            onChange={e => setUserName(e.currentTarget.value)}
                            value={userName}
                            placeholder="Benutzername"
                        />
                    </div>

                    <div className="form-group">
                        <label className="">Passwort</label>
                        <input
                            autoComplete="current-password"
                            className="form-control"
                            id="password"
                            type="password"
                            required
                            onChange={e => setPassword(e.currentTarget.value)}
                            value={password}
                            placeholder="Passwort"
                        />
                    </div>

                    <button type="submit" className="btn btn-primary" disabled={pending}>Login</button>

                </form>
            </div>
        </div>

    </KuesPage>
}