import { Fragment, useMemo } from "react"
import { API } from "../../api/api"
import { assertUnreachable, base64toBlob } from "../../utils"

export function ZB1Auswertung(props: {
    scan: API.AuswertungParamsInternal,
    auswertung: API.AuswertungResult
}) {

    // Erkannte Werte der Auswertung werden auf FieldGroupData abgebildet. Dort
    // sind dann alle Informationen, die zur Darstellung benötigt werden, gebündelt.
    const groups: FieldGroupData[] = useMemo(() => auswertungToFieldGroupData(props.auswertung), [props.auswertung]);

    const tableBodyJSX = groups.map((g, i) => <FieldGroup key={i} group={g} />)

    // Finale Ansicht zurückgeben
    return <Fragment>
        <div style={{ textAlign: "center", paddingBottom: "1.5em" }}>
            <ScanDarstellung scan={props.scan} />
        </div>
        {
            (props.auswertung.fahrzeugdaten && !API.isFahrzeugdatenEmpty(props.auswertung.fahrzeugdaten))
                ? <Fragment>
                    <h2>Fahrzeugdaten</h2>
                    <div style={{ paddingBottom: "2em" }}>
                        <Fahrzeugdaten fahrzeugdaten={props.auswertung.fahrzeugdaten} />
                    </div>
                </Fragment>
                : undefined
        }
        {
            props.auswertung.fahrzeugdaten
                ? <h2>Erkannte Werte</h2>
                : undefined
        }
        <table className="table">
            {/* 
            <thead className="thead-dark">
                 <tr>
                    <th scope="col" className="p-3">Bezeichnung</th>
                    <th scope="col" className="p-3" colSpan={2}>Wert</th>
                    <th scope="col" className="p-3 text-right">Wahrscheinlichkeit</th>
                </tr> 
            </thead> 
            */}
            <tbody>
                {tableBodyJSX}
            </tbody>
        </table>
    </Fragment>
}

/**
 * FieldGroup fasst erkannte Werte (z.B. die 5 erkannten Werte zu einer FIN) als 
 * Gruppe zusammen. Das zugehörige Label und Snippet kann dann im Tabellenkopf der Grupppe 
 * dargestellt weden, während die erkannten Werte als Tabelleneintrag erscheinen.
 */
interface FieldGroupData {
    werte: (API.ValueProbability | API.ValueProbabilityText)[];
    label: JSX.Element | string;
    snippetBase64?: string;
}

function FieldGroupHeading(props: { group: FieldGroupData }) {
    const hasSnippet = props.group.snippetBase64 ? true : false;
    if (hasSnippet) {
        return <tr className="thead-dark">
            <th scope="col" colSpan={3} className="p-3">
                <div className="row">
                    <div className="col-md-4 col-sm-12">
                        {props.group.label}
                    </div>
                    <div className="col-md-8 col-sm-12">
                        <img
                            className="img-fluid float-right d-block"
                            alt="Bildausschnitt"
                            style={{ maxHeight: "5em" }}
                            src={"data:;base64, " + props.group.snippetBase64}
                        />
                    </div>
                </div>

            </th>
        </tr>
    } else {
        return <tr className="thead-dark">
            <th scope="col" colSpan={3} className="p-3">
                {props.group.label}
            </th>
        </tr>
    }
}

function FieldGroupValue(props: { erkannterWert: API.ValueProbability | API.ValueProbabilityText }) {
    if (API.isValueProbabilityText(props.erkannterWert)) {
        return <tr>
            <td>{props.erkannterWert.value}</td>
            <td><i>{props.erkannterWert.text}</i></td>
            <td className="text-right"><PercentLabel value={props.erkannterWert.probability} /></td>
        </tr>
    } else {
        return <tr>
            <td>{props.erkannterWert.value}</td>
            <td className="text-right" colSpan={2}><PercentLabel value={props.erkannterWert.probability} /></td>
        </tr>
    }
}

function FieldGroup(props: { group: FieldGroupData }) {
    const werte = props.group.werte.map((v, i) => <FieldGroupValue erkannterWert={v} key={i} />)
    return < Fragment >
        <FieldGroupHeading group={props.group} />
        {werte}
        <tr style={{ height: "1em" }}></tr>
    </Fragment >
}

function auswertungToFieldGroupData(auswertung: API.AuswertungResult): FieldGroupData[] {
    const groups: FieldGroupData[] = [];
    if (auswertung.fin) {
        groups.push({
            werte: auswertung.fin,
            label: <div>
                <div>FIN</div>
                <div><small>(Fahrzeug-Identifizierungsnummer)</small></div>
            </div>,
            snippetBase64: auswertung.fin_snippet,
        })
    }
    if (auswertung.fin_pruefziffer) {
        groups.push({
            werte: auswertung.fin_pruefziffer,
            label: "FIN Prüfziffer",
            snippetBase64: auswertung.fin_snippet,
        })
    }
    if (auswertung.hsn_tsn) {
        groups.push({
            werte: auswertung.hsn_tsn,
            label: <div>
                <div>HSN/TSN</div>
                <div><small>(Hersteller- und Typschlüsselnummer)</small></div>
            </div>,
            snippetBase64: auswertung.hsn_tsn_snippet,
        })
    }
    if (auswertung.hsn_tsn_pruefziffer) {
        groups.push({
            werte: auswertung.hsn_tsn_pruefziffer,
            label: "HSN/TSN Prüfziffer",
            snippetBase64: auswertung.hsn_tsn_snippet,
        })
    }
    if (auswertung.datum_erstzulassung) {
        groups.push({
            werte: auswertung.datum_erstzulassung,
            label: "Datum Erstzulassung",
            snippetBase64: auswertung.datum_erstzulassung_snippet,
        })
    }
    if (auswertung.datum_erstzulassung_lang) {
        groups.push({
            werte: auswertung.datum_erstzulassung_lang,
            label: "Datum Erstzulassung (lang)",
            snippetBase64: auswertung.datum_erstzulassung_snippet,
        })
    }
    if (auswertung.kennzeichen) {
        groups.push({
            werte: auswertung.kennzeichen,
            label: "Kennzeichen",
            snippetBase64: auswertung.kennzeichen_snippet,
        })
    }
    if (auswertung.emissionsklasse) {
        groups.push({
            werte: auswertung.emissionsklasse,
            label: <div>Emissionsklasse</div>,
            snippetBase64: auswertung.emissionsklasse_snippet,
        })
    }
    if (auswertung.kraftstoff_code) {
        groups.push({
            werte: auswertung.kraftstoff_code,
            label: <div>Kraftstoffcode</div>,
            snippetBase64: auswertung.kraftstoff_code_snippet,
        })
    }
    if (auswertung.name) {
        groups.push({
            werte: auswertung.name,
            label: <div>Halter &rarr; Name</div>,
            snippetBase64: auswertung.name_snippet,
        })
    }
    if (auswertung.vorname) {
        groups.push({
            werte: auswertung.vorname,
            label: <div>Halter &rarr; Vorname</div>,
            snippetBase64: auswertung.vorname_snippet,
        })
    }
    if (auswertung.strasse) {
        groups.push({
            werte: auswertung.strasse,
            label: <div>Halter &rarr; Straße</div>,
            snippetBase64: auswertung.adresse_snippet,
        })
    }
    if (auswertung.plz) {
        groups.push({
            werte: auswertung.plz,
            label: <div>Halter &rarr; Postleitzahl</div>,
            snippetBase64: auswertung.adresse_snippet,
        })
    }
    if (auswertung.ort) {
        groups.push({
            werte: auswertung.ort,
            label: <div>Halter &rarr; Ort</div>,
            snippetBase64: auswertung.adresse_snippet,
        })
    }
    if (auswertung.letzte_zulassung) {
        groups.push({
            werte: auswertung.letzte_zulassung,
            label: <div>Halter &rarr; Datum der Zulassung</div>,
            snippetBase64: auswertung.letzte_zulassung_snippet,
        })
    }
    if (auswertung.bereifung_achse_1) {
        groups.push({
            werte: auswertung.bereifung_achse_1,
            label: <div>Bereifung &rarr; Achse 1</div>,
            snippetBase64: auswertung.bereifung_snippet,
        })
    }
    if (auswertung.bereifung_achse_2) {
        groups.push({
            werte: auswertung.bereifung_achse_2,
            label: <div>Bereifung &rarr; Achse 2</div>,
            snippetBase64: auswertung.bereifung_snippet,
        })
    }
    return groups;
}


export function Fahrzeugdaten(props: { fahrzeugdaten: API.Fahrzeugdaten }) {
    return <table className="table">
        <thead className="thead-dark">
            <tr>
                <th scope="col" className="p-3">Bezeichnung</th>
                <th scope="col" className="p-3">Wert</th>
            </tr>
        </thead>
        <tbody>
            {
                props.fahrzeugdaten.fahrzeugklasse
                    ? <tr>
                        <td>Fahrzeugklasse</td>
                        <td>{props.fahrzeugdaten.fahrzeugklasse}</td>
                    </tr>
                    : undefined
            }
            {
                props.fahrzeugdaten.anzahl_sitze
                    ? <tr>
                        <td>Aufbauart</td>
                        <td>{props.fahrzeugdaten.code_aufbau}</td>
                    </tr>
                    : undefined
            }
            {
                props.fahrzeugdaten.hubraum
                    ? <tr>
                        <td>Hubraum in ccm</td>
                        <td>{props.fahrzeugdaten.hubraum}</td>
                    </tr>
                    : undefined
            }
            {
                props.fahrzeugdaten.nennleistung
                    ? <tr>
                        <td>Leistung in kW</td>
                        <td>{props.fahrzeugdaten.nennleistung}</td>
                    </tr>
                    : undefined
            }
            {
                props.fahrzeugdaten.anzahl_sitze
                    ? <tr>
                        <td>Anzahl Sitzplätze</td>
                        <td>{props.fahrzeugdaten.anzahl_sitze}</td>
                    </tr>
                    : undefined
            }
        </tbody>
    </table>
}



/**
 * Darstellung des eingelesenen Scan (Bilddatei oder PDF) in der Auswertung.
 */
export function ScanDarstellung(props: {
    scan: API.AuswertungParamsInternal
}) {
    const eingabeTyp = props.scan.type;
    if (eingabeTyp === 'pdf') {
        // Base64-PDF in URL umwandeln, damit Parameter zur Anzeige der Toolbar und zum automatischen Zoom angegeben werden können
        const url = URL.createObjectURL(base64toBlob(props.scan.base64Data, "application/pdf")) + '#toolbar=1&scrollbar=1&view=Fit'
        return <object style={{ width: "100%", height: "40vh" }} type="application/pdf" data={url}>
            <embed style={{ width: "100%", height: "40vh" }} type="application/pdf" src={url} />
        </object>
    } else if (eingabeTyp === 'image') {
        return <img
            alt="Eingelesene Bilddatei"
            className="img-fluid rounded"
            src={"data:image;base64, " + props.scan.base64Data}
        />
    } else {
        assertUnreachable(eingabeTyp, 'Nicht unterstütztes Eingabeformat')
    }
    return <div>Keine Vorschau der eingelesenen Datei verfügbar</div>
}

export function PercentLabel(props: {
    /**
     * Prozentsatz als Faktor: 0.0 sind 0% und 1.0 sind 100%
     */
    value: number
}) {
    var percent = (props.value * 100).toFixed(2)
    var color = "red";
    color = props.value > 0.6 ? "orange" : color
    color = props.value > 0.9 ? "green" : color
    return <span style={{ color: color }}>{percent}%</span>
}