import React from 'react';
import { createRoot } from 'react-dom/client';

import "./bootstrap-fzsd.scss"
import "./style.less"

import { StoreContext, useStore } from "./hooks/store";
import { PageLogin } from "./pages/page-login";
import { PageZB1Auswerten } from './pages/page-auswertung';

import { API } from './api/api';

import { APIClientAxios } from './api/api-client-axios'; 
const api: API = new APIClientAxios()

// import { APIClientMemory } from './api/api-client-memory'; 
// const api: API = new APIClientMemory()

function App() {
    const store = useStore(api)
    return <React.StrictMode>
        <StoreContext.Provider value={store}>
            {
                store.auth.user
                    ? <PageZB1Auswerten />
                    : <PageLogin />
            }
        </StoreContext.Provider>
    </React.StrictMode>
}

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />); 