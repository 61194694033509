/**
 * API ist ein Interface, das alle verfügbaren (oder benötigten) API-Aufrufe enthält. Ein API-Client kann 
 * das Interface implementieren und bietet so einen einfachen Zugriff auf die verfügbare Funktionaliät. 
 * 
 * Vorteil: Im Frontend wird nur an einer Stelle mit HTTP-Aufrufen hantiert. Auch andere API-Clients (Mocks, 
 * Client der direkt gegen den KI-Container arbeitet, ...) sind denkbar.
 */
export interface API {
    user(params: API.UserParams): Promise<API.UserResult>;
    login(params: API.LoginParams): Promise<API.LoginResult>
    logout(): Promise<void>;
    auswerten(params: API.AuswertungParamsInternal): Promise<API.AuswertungResult>
}

/**
 * Hier sind Datenstrukturen der API-Anfragen und -Antworten definiert. Diese müssen mit den
 * Strukturen, die der Server benötigt oder zurückgibt übereinstimmen! 
 * Zusätzlich werden einige Konstanten und Hilfsfunktionen gegeben.
 */
export namespace API {

    /**
     * Maximale Dateigröße beim Hochladen von Dateien. Angegeben in Byte.
     */
    export const MAX_FILESIZE = 10 * 1024 * 1024;

    /**
     * Maximale Länge oder Breite (je nachdem was größer ist) des Bildes. Angegeben in Pixel.
     */
    export const MAX_PIXELSIZE = 8192;


    /**
     * Schnittstellen-Rückgabe des Login
     */
    export interface LoginResult { token: string }

    /**
     * Schnittstellen-Parameter für den Login 
     */
    export interface LoginParams { loginname: string, passwort: string }


    /**
     * Schnittstellen-Parameter zum Afragen des eingeloggten Benutzer
     */    
    export interface UserParams { }

    /**
     * Schnittstellen-Rückgabe mit Informationen über den eingeloggten Benutzer 
     */
    export interface UserResult {
        id: number
        created_at: string
        updated_at: string
        disabled: 0 | 1
        active_from: null | string
        active_to: null | string
        username: string
        email: string
        address_id: -1 | number
        api_token: string
        api_service: string
        max_requests_per_minute: number
        tags: UserTag[]
    }

    export interface UserTag {
        id: number;
        name: string;
        displayname: string;
        tooltip: string;
        subscription: {
            user_id: number;
            tag_id: number;
            expires: null | string;
        }
    }

    /**
     * Interne Parameter um eine Auswertung anzufordern. Hier sind Zusatzinformationen enthalten, damit
     * das Frontend z. B. Validierungen durchführen kann.
     */
    export interface AuswertungParamsInternal {
        type: 'image' | 'pdf';
        base64Data: string;
    }

    /**
     * Schnittstellen-Parameter um eine Auswertung anzufordern. 
     */
    export interface AuswertungParams {
        ref_nr?: string;
        application: string;
        image: string;
    }

    /**
     * Schnittstellen-Rückgabe der Auswertung
     */
    export interface AuswertungResult {

        /** Bildausschnitt für die Felder `plz`, `strasse` und `ort`. (Ziffer C1.3) */
        adresse_snippet?: string; // Seit Revision 3.3

        /** Bereifung der 1. Achse. (Ziffer 15.1) */
        bereifung_achse_1?: ValueProbability[]; // Seit Revision 3.3 

        /** Bereifung der 2. Achse. (Ziffer 15.2) */
        bereifung_achse_2?: ValueProbability[]; // Seit Revision 3.3 

        /** Bildausschnitt für die Felder `bereifung_achse_1` und `bereifung_achse_2`. (Ziffern 15.1 und 15.2) */
        bereifung_snippet?: string; // Seit Revision 3.3

        /** Datum der Erstzulassung. Jahr und Monat im Format YYYY-MM. (Ziffer B) */
        datum_erstzulassung?: ValueProbability[];

        /** Bildausschnitt für das Feld `datum_erstzulassung`. (Ziffer B) */
        datum_erstzulassung_snippet?: string; // Seit Revision 3.3

        /** Datum der Erstzulassung (Lang). Tag, Jahr und Monat im Format YYYY-MM-DD. (Ziffer B) */
        datum_erstzulassung_lang?: ValueProbability[];

        /** Schlüssel der Emissionsklasse. Beinhaltet den Klartext zum Code. (Ziffer 14.1) */
        emissionsklasse?: ValueProbabilityText[]; // Seit Revision 3.2

        /** Bildausschnitt für das Feld `emissionsklasse`. (Ziffer 14.1) */
        emissionsklasse_snippet?: string; // Seit Revision 3.3

        // erstzulassung_lang: ErkannterWert[]; // Undokumentiert

        /** Zusatzinformationen aus einer Fahrzeugdatenbank */
        fahrzeugdaten?: Fahrzeugdaten; // Seit Revision 3.4

        /** Fahrzeug-Identifizierungsnummer (FIN). (Ziffer E) */
        fin?: ValueProbability[];

        /** Bildausschnitt für die Felder `fin` und `fin_pruefziffer`. (Ziffern E und 3) */
        fin_snippet?: string; // Seit Revision 3.3

        /** Prüfziffer der FIN. (Ziffer 3) */
        fin_pruefziffer?: ValueProbability[];

        // fin_und_pruefziffer: ErkannterWert[]; // Undokumentiert

        /** Hersteller und Typschlüssel (HSN/TSN). HSN, TSN und VVS kombiniert. (Ziffer 2.2) */
        hsn_tsn?: ValueProbability[];

        /** Bildausschnitt für die Felder `hsn_tsn` und `hsn_tsn_pruefziffer`. (Ziffern 2.2) */
        hsn_tsn_snippet?: string; // Seit Revision 3.3

        /** Prüfziffer zu HSN/TSN. (Ziffer 2.2) */
        hsn_tsn_pruefziffer?: ValueProbability[];

        // hsn_tsn_und_pruefziffer: ErkannterWert[]; // Undokumentiert

        /** Amtliches Kennzeichen. (Ziffer A) */
        kennzeichen?: ValueProbability[];

        /** Bildausschnitt für das Feld `kennzeichen`. (Ziffern A) */
        kennzeichen_snippet?: string; // Seit Revision 3.3

        /** Code der Kraftstoffart. Beinhaltet den Klartext zum Code. (Ziffer 10) */
        kraftstoff_code?: ValueProbabilityText[]; // Seit Revision 3.2

        /** Bildausschnitt für das Feld `kraftstoff_code`. (Ziffern 10) */
        kraftstoff_code_snippet?: string; // Seit Revision 3.3

        /** Datum der Zulassung (auf den Halter). Tag, Jahr und Monat im Format DD.MM.YYYY. (Ziffer I) */
        letzte_zulassung?: ValueProbability[]; // Seit Revision 3.2

        /** Bildausschnitt für das Feld `letzte_zulassung`. (Ziffern I) */
        letzte_zulassung_snippet?: string; // Seit Revision 3.3

        /** Name oder Firmenname des Halters. (Ziffer C1.1) */
        name?: ValueProbability[];

        /** Bildausschnitt für das Feld `name`. (Ziffern C1.1) */
        name_snippet?: string; // Seit Revision 3.3

        /** Wohnort Halteranschrift. (Ziffer C1.3) */
        ort?: ValueProbability[];

        /** Postleitzahl Halteranschrift. (Ziffer C1.3) */
        plz?: ValueProbability[];

        /** Straße Halteranschrift. (Ziffer C1.3) */
        strasse?: ValueProbability[];

        /** Vorname(n) des Halters. (Ziffer C1.2) */
        vorname?: ValueProbability[];

        /** Bildausschnitt für das Feld `vorname`. (Ziffern C1.2) */
        vorname_snippet?: string; // Seit Revision 3.3 

    }

    /**
     * Fahrzeugdaten zu der HSN/TSN. 
     */
    export interface Fahrzeugdaten {
        /** Anzahl Sitzplätze. (Ziffer S.1) */
        anzahl_sitze: null | number;
        /** Aufbauart. (Ziffer 4) */
        code_aufbau: null | string;
        /** Fahrzeugklasse. (Ziffer J) */
        fahrzeugklasse: null | string;
        /** Hubraum in ccm . (Ziffer P.1) */
        hubraum: null | number;
        /** Leistung in kW. (Ziffer P.2) */
        nennleistung: null | number;
    }

    export interface ValueProbability {
        /** Ausgelesener Wert */
        value: string,
        /** Die Wahrscheinlichkeit. Ein Wert zwischen 0.0 und 1.0 */
        probability: number,
    }

    export interface ValueProbabilityText extends ValueProbability {
        /** Klartext, falls verfügbar */
        text: string;
    }

    export function isFahrzeugdatenEmpty(daten: Fahrzeugdaten): boolean {
        return (daten.anzahl_sitze === null)
            && (daten.code_aufbau === null)
            && (daten.fahrzeugklasse === null)
            && (daten.hubraum === null)
            && (daten.nennleistung === null)
    }

    export function isValueProbabilityText(value: ValueProbability): value is ValueProbabilityText {
        return (value as ValueProbabilityText).text !== undefined;
    }



}