
export function assertUnreachable(value: never, message?: string) {
    throw new Error(`Nicht abgedeckter Wert: "${value}". ${message}`)
}

export async function toBase64(file: Blob | File): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const result = reader.result;
            if (result) {
                if (typeof result === "string") {
                    const marker = ";base64,";
                    const startIndex = result.indexOf(marker) + marker.length;
                    resolve(result.substr(startIndex));
                } else {
                    reject(new Error("converting ArrayBuffer to string not supported now"))
                }
            } else {
                reject(new Error("read null from file"))
            }
        };
        reader.onerror = error => reject(error);
    })
}

/**
 * Anzahl von Bytes in lesbare Zeichenkette, z.B. `humanReadableFileSize(123456) // = ''`
 * 
 * @param sizeBytes 
 */
export function humanReadableFileSize(sizeBytes: number): string {
    if (sizeBytes === 0) return "0 Byte";
    const UNITS = ['Byte', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.min(UNITS.length - 1, Math.floor(Math.log(sizeBytes) / Math.log(1024)));
    return (sizeBytes / Math.pow(1024, i)).toFixed(i === 0 ? 0 : 2) + ' ' + UNITS[i];
};

/**
 * Gibt die Auflösung eines Bildes zurück. Im Fehlefall wird undefined zurückgegeben.
 * @param source File-Objekt oder Base64-String mit Binärdaten
 */
export async function getImageSize(source: File | string): Promise<{ width: number, height: number } | undefined> {
    return new Promise(async (res) => {
        try {
            const image = new Image()
            image.onload = function () {
                if (image.width <= 0 || image.height <= 0) {
                    res(undefined);
                } else {
                    res({ width: image.width, height: image.height });
                }
            };
            image.onerror = function () {
                res(undefined);
            }
            const base64 = (typeof source === "string")
                ? source
                : await toBase64(source);

            image.src = `data:image/*;base64,${base64}`;
        } catch (error) {
            res(undefined);
        }
    })
}

// https://stackoverflow.com/questions/35371246/data-uri-hash-parameters-hide-pdf-toolbar-for-data-uri
export function base64toBlob(b64Data: string, contentType: string) {
    var byteCharacters = window.atob(b64Data)
    var byteArrays = []
    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        var slice = byteCharacters.slice(offset, offset + 512),
            byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }
        var byteArray = new Uint8Array(byteNumbers)
        byteArrays.push(byteArray)
    }
    return new Blob(byteArrays, { type: contentType })
}

