import { useState, useCallback, Fragment, useEffect, useMemo } from "react"
import { useContext } from "react"

import { StoreContext } from "../hooks/store"
import { KuesPage } from "../components/kues-page"
import { API } from "../api/api"
import { CodeBox } from "../components/code-box"
import { ZB1Auswertung } from "./auswertung/zb1-auswertung"
import { ZB1Eingabe } from "./auswertung/zb1-eingabe"


export function PageZB1Auswerten() {
    const { api } = useContext(StoreContext)
    const [scan, setScan] = useState<API.AuswertungParamsInternal | undefined>(undefined)
    const [auswertung, setAuswertung] = useState<API.AuswertungResult | undefined>(undefined); // auswertung, null=fehler, undefined=pending
    const [auswertungPending, setAuswertungPending] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

    const [showJson, setShowJson] = useState(false)
    const auswertungJson = useMemo(() => JSON.stringify(auswertung, null, 4), [auswertung])

    const onNeueAuswertungStarten = useCallback(() => {
        setScan(undefined)
        setAuswertung(undefined)
        setErrorMessage(undefined)
        setAuswertungPending(false)
        setShowJson(false)
    }, [setScan, setAuswertung, setErrorMessage, setAuswertungPending, setShowJson])


    useEffect(() => {
        if (!scan) {
            return
        }
        let canceled = false;
        (async function () {
            try {
                setAuswertung(undefined);
                setAuswertungPending(true);
                const result = await api.auswerten(scan);
                if (canceled) {
                    return;
                }
                setAuswertung(result);
            } catch (error) {
                const errorMessage = error instanceof Error ? error.message : 'Unbekannte Ursache';
                setErrorMessage(`Daten können nicht ausgewertet werden. (${errorMessage}) `)
            } finally {
                setAuswertungPending(false)
            }
        }())
        return () => { canceled = true }
    }, [scan, api])

    const errorMessageJSX = errorMessage
        ? <div className="alert alert-danger">
            <b>Fehler</b>: {errorMessage}
        </div>
        : undefined

    let contentJSX: JSX.Element | undefined = undefined;
    if (auswertung && scan) {
        contentJSX = <Fragment>
            <ZB1Auswertung
                scan={scan}
                auswertung={auswertung}
            />

            {
                showJson
                    ? <div className="card" ref={ref => ref?.scrollIntoView({ behavior: "smooth" })}>
                        <div className="card-header">Webservice-Rückgabe</div>
                        <div className="card-body">
                            <CodeBox code={auswertungJson} language="json" />
                        </div>
                    </div>
                    : undefined
            }

            <div className="d-flex justify-content-between">
                <button className="btn btn-primary m-2" onClick={onNeueAuswertungStarten}>Weitere Auswertung starten...</button>
                <button className="btn btn-secondary m-2" onClick={() => setShowJson(x => !x)}>Webservice-Rückgabe {showJson ? "ausblenden" : "anzeigen"}</button>
            </div>


        </Fragment>
    } else {
        if (scan) {
            if (auswertungPending) {
                contentJSX = <div>Auswertung läuft...</div>
            }
        } else {
            contentJSX = <ZB1Eingabe setScan={setScan} />
        }
    }

    return <KuesPage>
        {errorMessageJSX}
        {contentJSX}
    </KuesPage>
}

