import React, { useState, useCallback, Fragment, useEffect } from "react"

import { API } from "../../api/api"
import { validiereAuswertungParams } from "../../api/validator"
import { extname } from "path-browserify"
import { toBase64 } from "../../utils"


export function ZB1EingabeVonDatei(props: {
    setScan: (scan: API.AuswertungParamsInternal) => void
}) {
    const { setScan } = props;
    const [file, setFile] = useState<File | undefined>(undefined);
    const [isDragOver, setIsDragOver] = useState(false);
    const [errorMessage, setErrorMessage] = useState<undefined | string>(undefined)

    const onAuswertungStarten = useCallback(async () => {
        if (file) {
            const scan = await fileToScan(file)
            setScan(scan)
        }
    }, [file, setScan])

    useEffect(() => {
        let abort = false;
        (async function () {
            if (file) {
                try {
                    const scan = await fileToScan(file)
                    if (abort) return;
                    await validiereAuswertungParams(scan)
                    if (abort) return;
                    setErrorMessage(undefined);
                } catch (error) {
                    if (abort) return;
                    const errorMessage = error instanceof Error ? error.message : 'Unbekannter Fehler';
                    setErrorMessage(errorMessage)
                }
            }
        }())
        return () => {
            abort = true;
        }
    }, [file, setErrorMessage])

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.currentTarget.files) {
            setFile(e.currentTarget.files.length > 0 ? e.currentTarget.files[0] : undefined);
        }
    }, [setFile])

    const dropHandler = useCallback((ev: React.DragEvent<HTMLDivElement>) => {
        ev.preventDefault();
        if (ev.dataTransfer.items) {
            for (let i = 0; i < ev.dataTransfer.items.length; i++) {
                if (ev.dataTransfer.items[i].kind === 'file') {
                    let file = ev.dataTransfer.items[i].getAsFile();
                    if (file) {
                        setFile(file)
                        return; // only one file
                    }
                }
            }
        } else {
            for (let i = 0; i < ev.dataTransfer.files.length; i++) {
                let file = ev.dataTransfer.files[i];
                if (file) {
                    setFile(file)
                    return; // only one file
                }
            }
        }
    }, [setFile])

    const buttonDisabled = (file ? false : true) || (errorMessage ? true : false); // Wenn keine Datei gewählt oder eine Fehlermeldung vorliegt

    const errorMessageJSX = errorMessage
        ? <div className="container pt-4">
            <div className="alert alert-danger" role="alert">
                {errorMessage}
            </div>
        </div>
        : <Fragment />;

    return <Fragment>
        <div className="input-group mb-3">
            <div className="custom-file">
                <input className="custom-file-input" type="file" onChange={onChange} />
                <label className="custom-file-label">{file ? file.name : "Keine Datei gewählt"}</label>
            </div>
        </div>

        <div className={"k-drop-zone " + (isDragOver ? "k-is-active" : "")}
            onDrop={e => { dropHandler(e); setIsDragOver(false) }}
            onDragEnd={() => setIsDragOver(false)}
            onDragLeave={() => setIsDragOver(false)}
            onDragEnter={() => setIsDragOver(true)}
            onDragOver={e => { e.preventDefault(); setIsDragOver(true); }} >
            <b>Bild-Datei hier ablegen...</b>
        </div>

        <button className="btn btn-primary"
            onClick={onAuswertungStarten}
            disabled={buttonDisabled}>
            Auswertung starten
        </button>

        {errorMessageJSX}

    </Fragment>

}


export async function fileToScan(file: File): Promise<API.AuswertungParamsInternal> {
    // Wir schauen hier nur auf die Dateiendung. Mittels file.type könnte auch der
    // Mime-Type abegfragt werden, allerdings scheint dies nicht unbedingt robuster zu sein, da
    // auch hier die Dateiendung herangezogen wird (und manipuliert sein könnte).
    // Siehe außerdem: https://textslashplain.com/2018/07/26/be-skeptical-of-client-reported-mime-content-types/
    const type = (extname(file.name).toLowerCase() === '.pdf') ? 'pdf' : 'image'
    const fileAsBase64 = await toBase64(file)
    return {
        type: type,
        base64Data: fileAsBase64,
    }
}


