
import { API } from "../../api/api"

import { ZB1EingabeVonDatei } from "./zb1-eingabe-datei"
import { ZB1EingabeVonKamera } from "./zb1-eingabe-kamera"


export function ZB1Eingabe(props: {
    setScan: (scan: API.AuswertungParamsInternal) => void
}) {
    const { setScan } = props;
    return <div className="row">
        <div className="col-xl-6 mb-4">
            <div className="card">
                <div className="card-header">
                    Bild-Datei oder PDF-Dokument auswerten
                </div>
                <div className="card-body">
                    <ZB1EingabeVonDatei setScan={setScan} />
                </div>
            </div>
        </div>
        <div className="col-xl-6 mb-4">
            <div className="card">
                <div className="card-header">
                    Kamera-Bild auswerten
                </div>
                <div className="card-body">
                    <ZB1EingabeVonKamera setScan={setScan} />
                </div>
            </div>
        </div>
    </div>
}


