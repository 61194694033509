import Axios, { AxiosInstance } from "axios"
import { API } from "./api"
import { validiereAuswertungParams } from "./validator";

const APPLICATION = "fahzeugschein-digital-demo";
const TOKEN = "token";

/**
 * APIClientAxios implementiert die API auf Basis von Axios. Dieser Client arbeitet
 * gegen den Fahrzeugschein Digital Proxy Server.
 */
export class APIClientAxios implements API {
    private _axios: AxiosInstance
    private _token: string | undefined = undefined;
    private _user: API.UserResult | undefined = undefined;

    public constructor() {
        this._token = localStorage.getItem(TOKEN) ?? undefined
        this._axios = setupAxios(this._token)
    }


    private async internalGetUser() {
        try {
            const userResponse = await this._axios.get("/api/user")
            const userResult = userResponse.data as API.UserResult // TODO: validation
            return userResult
        } catch (error) {
            const { message } = extractErrorInfo(error)
            throw new Error(message)
        }
    }

    public async login(params: API.LoginParams): Promise<API.LoginResult> {
        try {
            this._user = undefined;
            this._token = undefined;

            // login
            const loginResponse = await this._axios.post("/api/login", {
                loginname: params.loginname,
                passwort: params.passwort,
            })
            const loginResult = loginResponse.data as API.LoginResult // TODO: validation
            localStorage.setItem(TOKEN, loginResult.token)
            this._token = loginResult.token
            this._axios = setupAxios(loginResult.token)

            // get user
            var user = await this.internalGetUser() 
            this._user = user

            return loginResult
        } catch (error) {
            const { message } = extractErrorInfo(error)
            throw new Error(message)
        }
    }

    public async logout(): Promise<void> {
        this._token = undefined
        this._user = undefined
        this._axios = setupAxios(undefined);
        localStorage.removeItem(TOKEN);
    }

    public async user(params: API.UserParams): Promise<API.UserResult> {
        if (this._token && this._user) {
            return this._user
        }
        if(this._token){
            var user = await this.internalGetUser() 
            this._user = user
            return user
        }
        throw new Error("not logged in")
    }

    public async auswerten(params: API.AuswertungParamsInternal): Promise<API.AuswertungResult> {
        try {
            if (!this._token) {
                throw new Error("no api token available")
            }
            if (!this._user) {
                throw new Error("not logged in")
            }
            validiereAuswertungParams(params);

            const hasPermissionForSnippets = this._user.tags.some(t => t.name === "snippets");

            const endpoint = hasPermissionForSnippets ? "/api/v1/zb1/snippets" : "/api/v1/zb1";

            const apiParams: API.AuswertungParams = {
                ref_nr: undefined,
                application: APPLICATION,
                image: params.base64Data
            }

            const response = await this._axios.post(endpoint, apiParams)
            return response.data as API.AuswertungResult; // TODO: validation
        } catch (error) {
            const { message } = extractErrorInfo(error)
            throw new Error(message)
        }
    }

}


interface ErrorInfo {
    message: string;
}

function extractErrorInfo(axiosError: any): ErrorInfo {
    return {
        message: (axiosError?.response?.data?.message as string) ?? axiosError.message
    }
}

function setupAxios(token: string | undefined) {
    const axios = Axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        responseType: 'json',
        headers: {
            'Content-Type': 'application/json',
            ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
        },
    });
    return axios;
}

