
import { assertUnreachable, getImageSize, humanReadableFileSize } from "../utils";
import { API } from "./api"



/**
 * Validiert die Parameter der zb1Auswerten-Funktion
 */
export async function validiereAuswertungParams(eingabeScan: API.AuswertungParamsInternal) {
    // Grobe Prüfung zum erzwingen des Upload-Limit. Bei Base64 steigt der Platzbedarf um 33-36%.
    // Sollte bereits von der UI abgefangen werden - hier nochmal zur Sicherheit.
    if (eingabeScan.base64Data.length > (API.MAX_FILESIZE * 1.33)) {
        throw new Error(`Die Dateigröße des gewählten Bildes ist zu groß. Das Maximum liegt bei ${humanReadableFileSize(API.MAX_FILESIZE)}.`);
    }

    const eingabeTyp = eingabeScan.type;
    if (eingabeTyp === 'pdf') {
        // Bei PDFs kann nicht genauer validiert werden

    } else if (eingabeTyp === 'image') {
        // Auflösung von Bildern begrenzen.
        const res = await getImageSize(eingabeScan.base64Data);
        if (res) {
            if (res.width > API.MAX_PIXELSIZE || res.height > API.MAX_PIXELSIZE) {
                throw new Error(`Das gewählte Bild ist zu groß. Die Höhe oder Breite überschreitet ${API.MAX_PIXELSIZE} Pixel.`)
            }
        } else {
            throw new Error("Die Auflösung der Datei konnte nicht geprüft werden. Vermutlich handelt es sich nicht um ein Bild.")
        }

    } else {
        assertUnreachable(eingabeTyp, 'Nicht unterstütztes Eingabeformat')
    }
}
